<template>
  <main id="oem">
    <!-- TODO: potrzebuję  page.acf.header_slider -->
    <!-- <header-slider
      class="hidden desktopSmall:block"
      :slides="page.acf.header_slider" /> -->
      <div class="container">
        <div class="w-full tablet:w-3/4 mx-auto">
          <header-h1
            :content="`OEM: ${oemNumber}`"
            casing="uppercase"
            mt="0"
            mb="0"
            extraClass="text-h2" />
          <header-h2
            :content="`${$t('components.oem.oem_txt', {oem_number: oemNumber})}`"
            mt="4"
            mb="10"
            extraClass="text-base" />

          <div id="oem_products">
            <section>
              <div class="flex flex-wrap">
                <div class="w-full">
                  <div
                    v-if="loadError"
                    class="block bg-orange px-4 py-2 text-white">
                    {{ $t('components.oem.fail') }}
                  </div>
                  <div
                    v-if="itemsLoading && !products.length"
                    class="flex">
                    <font-awesome-icon
                      :icon="['fas', 'circle-notch']"
                      class="fa-spin fa-3x fa-fw mx-auto my-5"/>
                  </div>
                  <div v-else-if="!itemsLoading && !products.length">
                    {{ $t('components.oem.no_oem_found', {oem_number: oemNumber}) }}
                  </div>
                  <div v-for="product in products">
                    <product-item
                      :data="product"
                      :link="{
                        name: 'product-product',
                        params: {
                          product: $slugify(product.link)
                        }
                      }"/>
                  </div>
                  <client-only>
                    <infinite-loading
                      v-if="lastPage > 1"
                      v-show="true"
                      :identifier="infiniteId"
                      @infinite="infiniteScroll"
                      error=" "
                      no-results=" "
                      no-more=" "
                      spinner="spiral" />
                  </client-only>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import portal from '/service/portal';
import ProductItem from '/components/products/Item';

export default {
  name: 'Oem',
  components: { ProductItem },
  head () {
    const title = 'OEM - Air Conditioning Parts - AP Air Ltd.'
    const description = ''

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' }
      ]
    }
  },
  data () {
    return {
      infiniteId: 0,
      itemsLoading: false,
      lastPage: 1,
      page: 0,
      products: [],
      loadError: false
    }
  },
  async asyncData ({ params, app, store }) {
    // tutaj tylko request po WP
    // // const [page, products] = await Promise.all([
    // //   wordpress.get(`page/${app.i18n.t('endpoints.homepage')}`),
    // //   portal.get(`products/featured`)
    // // ])

    return {
      oemNumber: params.oemNumber
    }
  },
  async mounted () {
    this.itemsLoading = true;
    await this.loadItems()
    setTimeout(() => {
      this.itemsLoading = false;
    }, 200);
    // TODO: czy jakiś gtag? chyba nie jak to tylko do seo?
    // if (process.browser) {
    //   this.$gtag('event', 'your_event', {
    //     page_title: 'News',
    //     page_path: this.$route.fullPath
    //   })
    // }
  },
  methods: {
    async loadItems () {
      if (this.page + 1 > this.lastPage) {
        return;
      }

      try {
        let response = await portal.get(`products/search/hose?lang=${this.$store.state.language}&page=${this.page + 1}`);

        this.appendProducts(response.data.data);
        this.$set(this, 'page', response.data.current_page);
        this.$set(this, 'lastPage', response.data.last_page);
        this.itemsLoading = false;
      } catch (err) {
        console.log(err);
        this.itemsLoading = false;
        this.loadError = true;
      }
    },
    appendProducts (productsData) {
      let mappedProducts = productsData.map((product) => {
        for (let j = 0; j < product.translations.length; j++) {
          if (product.translations[j].language.language_code === this.$store.state.language ) {
            product.name = product.translations[j].name
          }
        }
        product.link = product.id + ' ' + product.sku + ' ' + product.name;

        return product;
      })

      mappedProducts = mappedProducts.filter(item => !!item)
      this.products = this.products.concat(mappedProducts);
    },
    async infiniteScroll($state) {
      if (!!this.itemsLoading) {
        return;
      }

      this.itemsLoading = true;

      await this.loadItems();

      if ($state) {
        if (this.page < this.lastPage) {
          $state.loaded();
          this.infiniteId++;
        } else {
          $state.complete();
        }
      }

      setTimeout(() => {
        this.itemsLoading = false;
      }, 200);
    },
  }
}
</script>

<style>
div {
  word-wrap: break-word;
}
</style>
